import "@reach/dialog/styles.css";
import "@reach/menu-button/styles.css";
import "@reach/tooltip/styles.css";
import * as React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import SDK from "./sdk";
import { SDKContext } from "./SDKContext";
import SDKRE from "./sdk-re";
import { SDKREContext } from "./SDKREContext";
import * as auth from "./shared/auth";
import { formatDocumentTitle } from "./shared/formatDocumentTitle";
import DocumentTitle from "./components/lib/DocumentTitle";
import Layout from "./components/Layout";
import GenericLoading from "./components/FullPageLoading";
import DefaultErrorBoundary from "./components/DefaultErrorBoundary";

const Login = React.lazy(() => import("./pages/Login/Login"));
const Systems = React.lazy(() => import("./pages/Systems/Systems"));
const SystemCreate = React.lazy(() =>
  import("./pages/SystemCreate/SystemCreate")
);
const Devices = React.lazy(() => import("./pages/Devices/Devices"));
const DeviceCreate = React.lazy(() =>
  import("./pages/DeviceCreate/DeviceCreate")
);
const Obj = React.lazy(() => import("./pages/Object/Object"));
const Operation = React.lazy(() => import("./pages/Operation/Operation"));
const Environment = React.lazy(() => import("./pages/Environment/Environment"));
const REObjectType = React.lazy(() =>
  import("./pages/RE_ObjectType/ObjectType")
);
const RERule = React.lazy(() => import("./pages/RE_Rule/Rule"));
const NotFound = React.lazy(() => import("./pages/404/404"));

export default function App() {
  let headers = {} as { [k: string]: any };
  const token = auth.getToken();
  if (token != null) {
    headers.Authorization = `Bearer ${token}`;
  }
  return (
    <DefaultErrorBoundary>
      <SDKContext.Provider
        value={{
          sdk: new SDK({
            baseUrl: process.env.REACT_APP_BASE_API_URL as string,
            headers,
          }),
        }}
      >
        <SDKREContext.Provider
          value={{
            sdk: new SDKRE({
              baseUrl: process.env.REACT_APP_BASE_API_URL as string,
              headers,
            }),
          }}
        >
          <BrowserRouter>
            <React.Suspense fallback={<GenericLoading />}>
              <Switch>
                <RedirectIfAuthenticatedRoute exact path="/login">
                  <DocumentTitle>{formatDocumentTitle("Login")}</DocumentTitle>
                  <Login />
                </RedirectIfAuthenticatedRoute>
                <Route exact path="/">
                  <Redirect to="/systems" />
                </Route>
                <PrivateRoute exact path="/devices">
                  <DocumentTitle>
                    {formatDocumentTitle("Devices")}
                  </DocumentTitle>
                  <Layout>
                    <Devices />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/devices/create">
                  <DocumentTitle>
                    {formatDocumentTitle("Create Device")}
                  </DocumentTitle>
                  <Layout>
                    <DeviceCreate />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/systems">
                  <DocumentTitle>
                    {formatDocumentTitle("Systems")}
                  </DocumentTitle>
                  <Layout>
                    <Systems />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/systems/create">
                  <DocumentTitle>
                    {formatDocumentTitle("Create System")}
                  </DocumentTitle>
                  <Layout>
                    <SystemCreate />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/systems/:uuid/objects">
                  <DocumentTitle>{formatDocumentTitle("System")}</DocumentTitle>
                  <Layout>
                    <Obj />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/systems/:uuid/environments">
                  <DocumentTitle>{formatDocumentTitle("System")}</DocumentTitle>
                  <Layout>
                    <Environment />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/objects/:uuid/operation">
                  <DocumentTitle>
                    {formatDocumentTitle("Operation")}
                  </DocumentTitle>
                  <Layout>
                    <Operation />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/object-types">
                  <DocumentTitle>
                    {formatDocumentTitle("Object Type")}
                  </DocumentTitle>
                  <Layout>
                    <REObjectType />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/rule">
                  <DocumentTitle>{formatDocumentTitle("Rule")}</DocumentTitle>
                  <Layout>
                    <RERule />
                  </Layout>
                </PrivateRoute>
                <Route>
                  <DocumentTitle>
                    {formatDocumentTitle("Not Found")}
                  </DocumentTitle>
                  <NotFound />
                </Route>
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </SDKREContext.Provider>
      </SDKContext.Provider>
    </DefaultErrorBoundary>
  );
}

// If already authenticated, redirect to `/` or `to`
function RedirectIfAuthenticatedRoute(props: RouteProps) {
  const { children, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.isAuthenticated()) {
          return children;
        }
        const searchParams = new URLSearchParams(props.location.search);
        const redirectKey = "to";
        const redirectLocation = searchParams.get(redirectKey) || "/";
        return <Redirect to={redirectLocation} />;
      }}
    />
  );
}

function PrivateRoute(props: RouteProps) {
  const { children, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        return auth.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: new URLSearchParams({
                to: props.location.search
                  ? props.location.pathname + props.location.search
                  : props.location.pathname,
              }).toString(),
            }}
          />
        );
      }}
    />
  );
}
