import * as React from "react";
import { NavLink } from "react-router-dom";
import cx from "classcat";
import {
  MdSettingsApplications,
  MdDevices,
  // MdLayers,
  // MdWbIridescent,
  // MdSystemUpdate,
  // MdAccountCircle,
  MdChevronLeft,
  MdChevronRight,
} from "react-icons/md";
import buttonStyles from "./lib/Button.module.css";
import styles from "./Sidebar.module.css";

export const links = [
  {
    text: "Systems",
    to: "/systems",
    icon: MdSettingsApplications,
  },
  {
    text: "Devices",
    to: "/devices",
    icon: MdDevices,
  },
  // {
  //   text: "Objects & Rules",
  //   to: "/objects",
  //   icon: MdWbIridescent,
  // },
  // {
  //   text: "Environments",
  //   to: "/environments",
  //   icon: MdLayers,
  // },
  // {
  //   text: "Targets",
  //   to: "/targets",
  //   icon: MdSystemUpdate,
  // },
  // {
  //   text: "Account",
  //   to: "/account",
  //   icon: MdAccountCircle,
  // },
];

const _width = 200;
const width = `${_width}px`;
const widthCollapsed = `${_width - 145}px`;

export default function () {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);
  React.useEffect(() => {
    const value = isSidebarCollapsed ? widthCollapsed : width;
    document.documentElement.style.setProperty("--sidebar-width", value);
  });

  return (
    <div className={styles.sidebar}>
      {/* <div className={styles.logo}>Configurator</div> */}
      <nav className={styles.nav}>
        <ul className={styles.nav_list}>
          {links.map((x, i) => {
            const Icon = x.icon;
            return (
              <li key={i}>
                <NavLink
                  to={x.to}
                  className={cx([
                    styles.nav_link,
                    isSidebarCollapsed && styles.nav_link_collapsed,
                  ])}
                  activeClassName={styles.nav_link_active}
                >
                  <Icon aria-hidden="true" className={styles.nav_link_icon} />
                  {isSidebarCollapsed ? null : (
                    <span className={styles.nav_link_text}>{x.text}</span>
                  )}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <div
        className={cx([
          styles.collapse_button_container,
          isSidebarCollapsed && styles.collapse_button_container_collapsed,
        ])}
      >
        <button
          type="button"
          onClick={() => {
            setIsSidebarCollapsed(!isSidebarCollapsed);
            const nextValue = isSidebarCollapsed ? width : widthCollapsed;
            document.documentElement.style.setProperty(
              "--sidebar-width",
              nextValue
            );
          }}
          className={buttonStyles.button_transparent_neutral}
        >
          {isSidebarCollapsed ? <MdChevronRight /> : <MdChevronLeft />}
        </button>
      </div>
    </div>
  );
}
