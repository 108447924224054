import * as React from "react";
import Media from "react-media";
import Header from "./Header";
import Sidebar from "./Sidebar";
import styles from "./Layout.module.css";

export default function Layout(props: { children: any }) {
  return (
    <>
      <Media queries={{ large: "(min-width: 768px)" }}>
        {(matches) => (
          <>
            <Header />
            {matches.large ? <Sidebar /> : null}
          </>
        )}
      </Media>
      <main className={styles.main}>{props.children}</main>
    </>
  );
}
