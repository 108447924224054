/* eslint-disable */

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var qs = require("qs");
var NodeCache = require("node-cache");
var cache = new NodeCache({
    // 10 min
    stdTTL: 600,
    // 5 min
    checkperiod: 300
});
var SDK = /** @class */ (function () {
    function SDK(opts) {
        var _a;
        this.removeResultingEventFromActionType = function (params, query, opts) {
            var path = "/v1/rules-engine/action-resulting-events/:actionResultingEventUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteActionType = function (params, query, opts) {
            var path = "/v1/rules-engine/action-types/:actionTypeUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteConditionalStatement = function (params, query, opts) {
            var path = "/v1/rules-engine/conditional-statements/:conditionalStatementUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteEventType = function (params, query, opts) {
            var path = "/v1/rules-engine/event-types/:eventTypeUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteObjectAttribute = function (params, query, opts) {
            var path = "/v1/rules-engine/object-attributes/:objectAttributeUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteObjectTypeAttribute = function (params, query, opts) {
            var path = "/v1/rules-engine/object-type-attributes/:objectTypeAttributeUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteObjectType = function (params, query, opts) {
            var path = "/v1/rules-engine/object-types/:objectTypeUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeParentObjectType = function (params, query, opts) {
            var path = "/v1/rules-engine/object-types/:objectTypeUuid/parents/:parentObjectTypeUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteObject = function (params, query, opts) {
            var path = "/v1/rules-engine/objects/:objectUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeParentObject = function (params, query, opts) {
            var path = "/v1/rules-engine/objects/:objectUuid/parents/:parentObjectUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteRuleAction = function (params, query, opts) {
            var path = "/v1/rules-engine/rule-actions/:ruleActionUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteRule = function (params, query, opts) {
            var path = "/v1/rules-engine/rules/:ruleUuid";
            return this._delete(path, params, query, opts);
        };
        this.removeConditionalStatementFromRule = function (params, query, opts) {
            var path = "/v1/rules-engine/rules/:ruleUuid/conditional-statements/:conditionalStatementUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteRuleTriggerEvent = function (params, query, opts) {
            var path = "/v1/rules-engine/rules/:ruleUuid/trigger-events/:triggerEventUuid";
            return this._delete(path, params, query, opts);
        };
        this.__setBaseUrl(opts.baseUrl);
        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
            this._headers = (_a = opts.headers) !== null && _a !== void 0 ? _a : {};
        }
    }
    SDK.prototype.__setBaseUrl = function (baseUrl) {
        this._baseUrl = baseUrl;
    };
    SDK.prototype.__setHeaders = function (fn) {
        this._headers = fn(this._headers);
    };
    SDK.prototype.__clearCache = function () {
        cache.flushAll();
    };
    SDK.prototype.getActionTypeList = function (params, query, opts) {
        var path = "/v1/rules-engine/action-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getActionType = function (params, query, opts) {
        var path = "/v1/rules-engine/action-types/:actionTypeUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateActionType = function (params, data, query, opts) {
        var path = "/v1/rules-engine/action-types/:actionTypeUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.addResultingEventForActionType = function (params, data, query, opts) {
        var path = "/v1/rules-engine/action-types/:actionTypeUuid/resulting-events";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getConditionalStatementList = function (params, query, opts) {
        var path = "/v1/rules-engine/conditional-statements";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getConditionalStatement = function (params, query, opts) {
        var path = "/v1/rules-engine/conditional-statements/:conditionalStatementUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateConditionalStatement = function (params, data, query, opts) {
        var path = "/v1/rules-engine/conditional-statements/:conditionalStatementUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getConditionalStatementResult = function (params, query, opts) {
        var path = "/v1/rules-engine/conditional-statements/:conditionalStatementUuid/result";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getEventTypeList = function (params, query, opts) {
        var path = "/v1/rules-engine/event-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getEventType = function (params, query, opts) {
        var path = "/v1/rules-engine/event-types/:eventTypeUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateEventType = function (params, data, query, opts) {
        var path = "/v1/rules-engine/event-types/:eventTypeUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getObjectAttributeList = function (params, query, opts) {
        var path = "/v1/rules-engine/object-attributes";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectAttribute = function (params, query, opts) {
        var path = "/v1/rules-engine/object-attributes/:objectAttributeUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateObjectAttribute = function (params, data, query, opts) {
        var path = "/v1/rules-engine/object-attributes/:objectAttributeUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getObjectTypeAttributeList = function (params, query, opts) {
        var path = "/v1/rules-engine/object-type-attributes";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectTypeAttribute = function (params, query, opts) {
        var path = "/v1/rules-engine/object-type-attributes/:objectTypeAttributeUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateObjectTypeAttribute = function (params, data, query, opts) {
        var path = "/v1/rules-engine/object-type-attributes/:objectTypeAttributeUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getObjectTypeList = function (params, query, opts) {
        var path = "/v1/rules-engine/object-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectType = function (params, query, opts) {
        var path = "/v1/rules-engine/object-types/:objectTypeUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateObjectType = function (params, data, query, opts) {
        var path = "/v1/rules-engine/object-types/:objectTypeUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.createActionType = function (params, data, query, opts) {
        var path = "/v1/rules-engine/object-types/:objectTypeUuid/action-types";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createObjectTypeAttribute = function (params, data, query, opts) {
        var path = "/v1/rules-engine/object-types/:objectTypeUuid/attributes";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createEventType = function (params, data, query, opts) {
        var path = "/v1/rules-engine/object-types/:objectTypeUuid/event-types";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createObject = function (params, data, query, opts) {
        var path = "/v1/rules-engine/object-types/:objectTypeUuid/objects";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.addParentObjectType = function (params, data, query, opts) {
        var path = "/v1/rules-engine/object-types/:objectTypeUuid/parents";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getObjectTypeTree = function (params, query, opts) {
        var path = "/v1/rules-engine/object-types/:objectTypeUuid/tree";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectList = function (params, query, opts) {
        var path = "/v1/rules-engine/objects";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObject = function (params, query, opts) {
        var path = "/v1/rules-engine/objects/:objectUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateObject = function (params, data, query, opts) {
        var path = "/v1/rules-engine/objects/:objectUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.addParentObject = function (params, data, query, opts) {
        var path = "/v1/rules-engine/objects/:objectUuid/parents";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getObjectTree = function (params, query, opts) {
        var path = "/v1/rules-engine/objects/:objectUuid/tree";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateRuleAction = function (params, data, query, opts) {
        var path = "/v1/rules-engine/rule-actions/:ruleActionUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getRuleList = function (params, query, opts) {
        var path = "/v1/rules-engine/rules";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getRule = function (params, query, opts) {
        var path = "/v1/rules-engine/rules/:ruleUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateRule = function (params, data, query, opts) {
        var path = "/v1/rules-engine/rules/:ruleUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.createRuleAction = function (params, data, query, opts) {
        var path = "/v1/rules-engine/rules/:ruleUuid/actions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.runRule = function (params, data, query, opts) {
        var path = "/v1/rules-engine/rules/:ruleUuid/actions/run";
        return this._put(path, params, data, query, opts);
    };
    SDK.prototype.addConditionalStatementToRule = function (params, data, query, opts) {
        var path = "/v1/rules-engine/rules/:ruleUuid/conditional-statements";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createRuleTriggerEvent = function (params, data, query, opts) {
        var path = "/v1/rules-engine/rules/:ruleUuid/trigger-events";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getSystemList = function (params, query, opts) {
        var path = "/v1/rules-engine/systems";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getSystem = function (params, query, opts) {
        var path = "/v1/rules-engine/systems/:systemUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.createConditionalStatement = function (params, data, query, opts) {
        var path = "/v1/rules-engine/systems/:systemUuid/conditional-statements";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createObjectType = function (params, data, query, opts) {
        var path = "/v1/rules-engine/systems/:systemUuid/object-types";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createRule = function (params, data, query, opts) {
        var path = "/v1/rules-engine/systems/:systemUuid/rules";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype._get = function (path, params, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, url, cacheKey, cached, headers, res, json, out;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        url = "" + this._baseUrl + hydratedPath + generateQueryString(query);
                        cacheKey = "GET" + url;
                        cached = cache.get(cacheKey);
                        if (cached !== undefined) {
                            return [2 /*return*/, cached];
                        }
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch(url, { headers: headers })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        out = { data: json, status: res.status };
                        cache.set(cacheKey, out);
                        return [2 /*return*/, out];
                }
            });
        });
    };
    SDK.prototype._post = function (path, params, data, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, headers, res, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch("" + this._baseUrl + hydratedPath + generateQueryString(query), {
                                method: "POST",
                                headers: headers,
                                body: JSON.stringify(data)
                            })];
                    case 1:
                        res = _a.sent();
                        if (res.status === 204) {
                            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                                cache.flushAll();
                            }
                            return [2 /*return*/, { data: undefined, status: res.status }];
                        }
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                            cache.flushAll();
                        }
                        return [2 /*return*/, { data: json, status: res.status }];
                }
            });
        });
    };
    SDK.prototype._patch = function (path, params, data, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, headers, res, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch("" + this._baseUrl + hydratedPath + generateQueryString(query), {
                                method: "PATCH",
                                headers: headers,
                                body: JSON.stringify(data)
                            })];
                    case 1:
                        res = _a.sent();
                        if (res.status === 204) {
                            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                                cache.flushAll();
                            }
                            return [2 /*return*/, { data: undefined, status: res.status }];
                        }
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                            cache.flushAll();
                        }
                        return [2 /*return*/, { data: json, status: res.status }];
                }
            });
        });
    };
    SDK.prototype._put = function (path, params, data, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, headers, res, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch("" + this._baseUrl + hydratedPath + generateQueryString(query), {
                                method: "PUT",
                                headers: headers,
                                body: JSON.stringify(data)
                            })];
                    case 1:
                        res = _a.sent();
                        if (res.status === 204) {
                            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                                cache.flushAll();
                            }
                            return [2 /*return*/, { data: undefined, status: res.status }];
                        }
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                            cache.flushAll();
                        }
                        return [2 /*return*/, { data: json, status: res.status }];
                }
            });
        });
    };
    SDK.prototype._delete = function (path, params, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, headers, res, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch("" + this._baseUrl + hydratedPath + generateQueryString(query), { method: "DELETE", headers: headers })];
                    case 1:
                        res = _a.sent();
                        if (res.status === 204) {
                            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                                cache.flushAll();
                            }
                            return [2 /*return*/, { data: undefined, status: res.status }];
                        }
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                            cache.flushAll();
                        }
                        return [2 /*return*/, { data: json, status: res.status }];
                }
            });
        });
    };
    return SDK;
}());
exports.default = SDK;
function findParamArguments(path, obj) {
    var parts = path.split(/\//);
    var params = parts.filter(function (p) { return p.length > 0 && p[0] === ":"; });
    var args = params.map(function (p) { return p.substring(1, p.length); });
    var out = {};
    var hydratedPath = path;
    if (obj) {
        args.forEach(function (e) {
            out[e] = obj[e];
            hydratedPath = hydratedPath.replace(":" + e, obj[e]);
        });
    }
    return { args: args, out: out, hydratedPath: hydratedPath };
}
function generateQueryString(opts) {
    return qs.stringify(opts, { addQueryPrefix: true, encode: true });
}
