import * as React from "react";
import { Link } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuLink,
  MenuItem,
} from "@reach/menu-button";
import Media from "react-media";
import { links } from "./Sidebar";
import styles from "./Header.module.css";

export default function Header() {
  return (
    <header className={styles.header}>
      <Link to="/" className={styles.link}>
        C
      </Link>
      <Menu>
        <MenuButton className={styles.menu_button} aria-label="Menu">
          <MdMenu />
        </MenuButton>
        <MenuList className={styles.menu_list}>
          <Media queries={{ large: "(min-width: 768px)" }}>
            {(matches) =>
              matches.large ? null : (
                <>
                  {links.map((x, i) => (
                    <MenuLink
                      key={i}
                      as={Link}
                      to={x.to}
                      className={styles.menu_item}
                    >
                      {x.text}
                    </MenuLink>
                  ))}
                </>
              )
            }
          </Media>
          <MenuItem
            onSelect={() => {
              window.localStorage.clear();
              window.location.href = "/login";
            }}
            className={styles.menu_item}
          >
            Sign out
          </MenuItem>
        </MenuList>
      </Menu>
    </header>
  );
}
