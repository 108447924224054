/* eslint-disable */

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var qs = require("qs");
var NodeCache = require("node-cache");
var cache = new NodeCache({
    // 10 min
    stdTTL: 600,
    // 5 min
    checkperiod: 300
});
var SDK = /** @class */ (function () {
    function SDK(opts) {
        var _a;
        this.deleteAction = function (params, query, opts) {
            var path = "/v1/org/actions/:actionUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteDevice = function (params, query, opts) {
            var path = "/v1/org/devices/:deviceUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteEnvironmentAttribute = function (params, query, opts) {
            var path = "/v1/org/environment-attributes/:environmentAttributeUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteEnvironmentBoundary = function (params, query, opts) {
            var path = "/v1/org/environment-boundaries/:environmentBoundaryUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteEnvironment = function (params, query, opts) {
            var path = "/v1/org/environments/:environmentUuid";
            return this._delete(path, params, query, opts);
        };
        this.disconnectObjectCommunications = function (params, query, opts) {
            var path = "/v1/org/object-communications/:objectCommunicationUuid/communications/:otherObjectCommunicationUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteObjectStatechart = function (params, query, opts) {
            var path = "/v1/org/object-statecharts/:objectStatechartUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteObject = function (params, query, opts) {
            var path = "/v1/org/objects/:objectUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteRuleExpression = function (params, query, opts) {
            var path = "/v1/org/rule-expressions/:ruleExpressionUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteRuleResultingAction = function (params, query, opts) {
            var path = "/v1/org/rule-resulting-actions/:ruleResultingActionUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteRule = function (params, query, opts) {
            var path = "/v1/org/rules/:ruleUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteStatechartStateTransition = function (params, query, opts) {
            var path = "/v1/org/statechart-state-transitions/:statechartStateTransitionUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteStatechartState = function (params, query, opts) {
            var path = "/v1/org/statechart-states/:statechartStateUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteStatechartTransientState = function (params, query, opts) {
            var path = "/v1/org/statechart-transient-states/:statechartTransientStateUuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteSystem = function (params, query, opts) {
            var path = "/v1/org/systems/:systemUuid";
            return this._delete(path, params, query, opts);
        };
        this.__setBaseUrl(opts.baseUrl);
        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
            this._headers = (_a = opts.headers) !== null && _a !== void 0 ? _a : {};
        }
    }
    SDK.prototype.__setBaseUrl = function (baseUrl) {
        this._baseUrl = baseUrl;
    };
    SDK.prototype.__setHeaders = function (fn) {
        this._headers = fn(this._headers);
    };
    SDK.prototype.__clearCache = function () {
        cache.flushAll();
    };
    SDK.prototype.getActionTypeList = function (params, query, opts) {
        var path = "/v1/org/action-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getActionList = function (params, query, opts) {
        var path = "/v1/org/actions";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getAction = function (params, query, opts) {
        var path = "/v1/org/actions/:actionUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateAction = function (params, data, query, opts) {
        var path = "/v1/org/actions/:actionUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getCommunicationTypeList = function (params, query, opts) {
        var path = "/v1/org/communication-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getDeviceList = function (params, query, opts) {
        var path = "/v1/org/devices";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getDevice = function (params, query, opts) {
        var path = "/v1/org/devices/:deviceUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateDevice = function (params, data, query, opts) {
        var path = "/v1/org/devices/:deviceUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getEnvironmentAttributeList = function (params, query, opts) {
        var path = "/v1/org/environment-attributes";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getEnvironmentAttribute = function (params, query, opts) {
        var path = "/v1/org/environment-attributes/:environmentAttributeUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateEnvironmentAttribute = function (params, data, query, opts) {
        var path = "/v1/org/environment-attributes/:environmentAttributeUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getEnvironmentBoundaryList = function (params, query, opts) {
        var path = "/v1/org/environment-boundaries";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getEnvironmentBoundary = function (params, query, opts) {
        var path = "/v1/org/environment-boundaries/:environmentBoundaryUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateEnvironmentBoundary = function (params, data, query, opts) {
        var path = "/v1/org/environment-boundaries/:environmentBoundaryUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getEnvironmentTypeList = function (params, query, opts) {
        var path = "/v1/org/environment-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getEnvironmentList = function (params, query, opts) {
        var path = "/v1/org/environments";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getEnvironment = function (params, query, opts) {
        var path = "/v1/org/environments/:environmentUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateEnvironment = function (params, data, query, opts) {
        var path = "/v1/org/environments/:environmentUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.createEnvironmentAttribute = function (params, data, query, opts) {
        var path = "/v1/org/environments/:environmentUuid/attributes";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createEnvironmentBoundary = function (params, data, query, opts) {
        var path = "/v1/org/environments/:environmentUuid/boundaries";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createEnvironmentOnEntryAction = function (params, data, query, opts) {
        var path = "/v1/org/environments/:environmentUuid/on-entry-actions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createEnvironmentOnExitAction = function (params, data, query, opts) {
        var path = "/v1/org/environments/:environmentUuid/on-exit-actions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getEnvironmentTree = function (params, query, opts) {
        var path = "/v1/org/environments/:environmentUuid/tree";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectCommunicationList = function (params, query, opts) {
        var path = "/v1/org/object-communications";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectCommunication = function (params, query, opts) {
        var path = "/v1/org/object-communications/:objectCommunicationUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateObjectCommunication = function (params, data, query, opts) {
        var path = "/v1/org/object-communications/:objectCommunicationUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.connectObjectCommunications = function (params, data, query, opts) {
        var path = "/v1/org/object-communications/:objectCommunicationUuid/communications";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getObjectIntegratedPeripheralList = function (params, query, opts) {
        var path = "/v1/org/object-integrated-peripherals";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectIntegratedPeripheral = function (params, query, opts) {
        var path = "/v1/org/object-integrated-peripherals/:objectIntegratedPeripheralUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateObjectIntegratedPeripheral = function (params, data, query, opts) {
        var path = "/v1/org/object-integrated-peripherals/:objectIntegratedPeripheralUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getObjectSignalList = function (params, query, opts) {
        var path = "/v1/org/object-signals";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectSignal = function (params, query, opts) {
        var path = "/v1/org/object-signals/:objectSignalUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateObjectSignal = function (params, data, query, opts) {
        var path = "/v1/org/object-signals/:objectSignalUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getObjectStatechartList = function (params, query, opts) {
        var path = "/v1/org/object-statecharts";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectStatechart = function (params, query, opts) {
        var path = "/v1/org/object-statecharts/:objectStatechartUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateObjectStatechart = function (params, data, query, opts) {
        var path = "/v1/org/object-statecharts/:objectStatechartUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getObjectStatechartWithTree = function (params, query, opts) {
        var path = "/v1/org/object-statecharts/:objectStatechartUuid/tree";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectTypeList = function (params, query, opts) {
        var path = "/v1/org/object-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObjectList = function (params, query, opts) {
        var path = "/v1/org/objects";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getObject = function (params, query, opts) {
        var path = "/v1/org/objects/:objectUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateObject = function (params, data, query, opts) {
        var path = "/v1/org/objects/:objectUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.createDevice = function (params, data, query, opts) {
        var path = "/v1/org/objects/:objectUuid/devices";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getObjectDisplayLayout = function (params, query, opts) {
        var path = "/v1/org/objects/:objectUuid/display-layout";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.replaceObjectDisplayLayout = function (params, data, query, opts) {
        var path = "/v1/org/objects/:objectUuid/display-layout";
        return this._put(path, params, data, query, opts);
    };
    SDK.prototype.createObjectStatechart = function (params, data, query, opts) {
        var path = "/v1/org/objects/:objectUuid/statecharts";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getPeripheralTypeList = function (params, query, opts) {
        var path = "/v1/org/peripheral-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getRuleExpressionList = function (params, query, opts) {
        var path = "/v1/org/rule-expressions";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getRuleExpression = function (params, query, opts) {
        var path = "/v1/org/rule-expressions/:ruleExpressionUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateRuleExpression = function (params, data, query, opts) {
        var path = "/v1/org/rule-expressions/:ruleExpressionUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getRuleResultingActionList = function (params, query, opts) {
        var path = "/v1/org/rule-resulting-actions";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getRuleResultingAction = function (params, query, opts) {
        var path = "/v1/org/rule-resulting-actions/:ruleResultingActionUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateRuleResultingAction = function (params, data, query, opts) {
        var path = "/v1/org/rule-resulting-actions/:ruleResultingActionUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getRuleTypeList = function (params, query, opts) {
        var path = "/v1/org/rule-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getRuleList = function (params, query, opts) {
        var path = "/v1/org/rules";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getRule = function (params, query, opts) {
        var path = "/v1/org/rules/:ruleUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateRule = function (params, data, query, opts) {
        var path = "/v1/org/rules/:ruleUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.createRuleExpression = function (params, data, query, opts) {
        var path = "/v1/org/rules/:ruleUuid/expressions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createRuleResultingAction = function (params, data, query, opts) {
        var path = "/v1/org/rules/:ruleUuid/resulting-actions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getSignalTypeList = function (params, query, opts) {
        var path = "/v1/org/signal-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getStateTypeList = function (params, query, opts) {
        var path = "/v1/org/state-types";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getStatechartStateTransitionList = function (params, query, opts) {
        var path = "/v1/org/statechart-state-transitions";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.getStatechartStateTransition = function (params, query, opts) {
        var path = "/v1/org/statechart-state-transitions/:statechartStateTransitionUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateStatechartStateTransition = function (params, data, query, opts) {
        var path = "/v1/org/statechart-state-transitions/:statechartStateTransitionUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.createStatechartStateTransitionAction = function (params, data, query, opts) {
        var path = "/v1/org/statechart-state-transitions/:statechartStateTransitionUuid/actions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getStatechartStateList = function (params, query, opts) {
        var path = "/v1/org/statechart-states";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.createStatechartState = function (params, data, query, opts) {
        var path = "/v1/org/statechart-states";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getStatechartState = function (params, query, opts) {
        var path = "/v1/org/statechart-states/:statechartStateUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateStatechartState = function (params, data, query, opts) {
        var path = "/v1/org/statechart-states/:statechartStateUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.getStatechartStateDisplayLayout = function (params, query, opts) {
        var path = "/v1/org/statechart-states/:statechartStateUuid/display-layout";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.replaceStatechartStateDisplayLayout = function (params, data, query, opts) {
        var path = "/v1/org/statechart-states/:statechartStateUuid/display-layout";
        return this._put(path, params, data, query, opts);
    };
    SDK.prototype.createStatechartStateOnEntryAction = function (params, data, query, opts) {
        var path = "/v1/org/statechart-states/:statechartStateUuid/on-entry-actions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createStatechartStateOnExitAction = function (params, data, query, opts) {
        var path = "/v1/org/statechart-states/:statechartStateUuid/on-exit-actions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createStatechartStateTransition = function (params, data, query, opts) {
        var path = "/v1/org/statechart-states/:statechartStateUuid/transitions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getStatechartTransientStateList = function (params, query, opts) {
        var path = "/v1/org/statechart-transient-states";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.createStatechartTransientState = function (params, data, query, opts) {
        var path = "/v1/org/statechart-transient-states";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getStatechartTransientState = function (params, query, opts) {
        var path = "/v1/org/statechart-transient-states/:statechartTransientStateUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateStatechartTransientState = function (params, data, query, opts) {
        var path = "/v1/org/statechart-transient-states/:statechartTransientStateUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.createStatechartTransientStateAction = function (params, data, query, opts) {
        var path = "/v1/org/statechart-transient-states/:statechartTransientStateUuid/actions";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getSystemList = function (params, query, opts) {
        var path = "/v1/org/systems";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.createSystem = function (params, data, query, opts) {
        var path = "/v1/org/systems";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.getSystem = function (params, query, opts) {
        var path = "/v1/org/systems/:systemUuid";
        return this._get(path, params, query, opts);
    };
    SDK.prototype.updateSystem = function (params, data, query, opts) {
        var path = "/v1/org/systems/:systemUuid";
        return this._patch(path, params, data, query, opts);
    };
    SDK.prototype.createEnvironment = function (params, data, query, opts) {
        var path = "/v1/org/systems/:systemUuid/environments";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createObject = function (params, data, query, opts) {
        var path = "/v1/org/systems/:systemUuid/objects";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype.createRule = function (params, data, query, opts) {
        var path = "/v1/org/systems/:systemUuid/rules";
        return this._post(path, params, data, query, opts);
    };
    SDK.prototype._get = function (path, params, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, url, cacheKey, cached, headers, res, json, out;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        url = "" + this._baseUrl + hydratedPath + generateQueryString(query);
                        cacheKey = "GET" + url;
                        cached = cache.get(cacheKey);
                        if (cached !== undefined) {
                            return [2 /*return*/, cached];
                        }
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch(url, { headers: headers })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        out = { data: json, status: res.status };
                        cache.set(cacheKey, out);
                        return [2 /*return*/, out];
                }
            });
        });
    };
    SDK.prototype._post = function (path, params, data, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, headers, res, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch("" + this._baseUrl + hydratedPath + generateQueryString(query), {
                                method: "POST",
                                headers: headers,
                                body: JSON.stringify(data)
                            })];
                    case 1:
                        res = _a.sent();
                        if (res.status === 204) {
                            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                                cache.flushAll();
                            }
                            return [2 /*return*/, { data: undefined, status: res.status }];
                        }
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                            cache.flushAll();
                        }
                        return [2 /*return*/, { data: json, status: res.status }];
                }
            });
        });
    };
    SDK.prototype._patch = function (path, params, data, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, headers, res, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch("" + this._baseUrl + hydratedPath + generateQueryString(query), {
                                method: "PATCH",
                                headers: headers,
                                body: JSON.stringify(data)
                            })];
                    case 1:
                        res = _a.sent();
                        if (res.status === 204) {
                            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                                cache.flushAll();
                            }
                            return [2 /*return*/, { data: undefined, status: res.status }];
                        }
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                            cache.flushAll();
                        }
                        return [2 /*return*/, { data: json, status: res.status }];
                }
            });
        });
    };
    SDK.prototype._put = function (path, params, data, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, headers, res, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch("" + this._baseUrl + hydratedPath + generateQueryString(query), {
                                method: "PUT",
                                headers: headers,
                                body: JSON.stringify(data)
                            })];
                    case 1:
                        res = _a.sent();
                        if (res.status === 204) {
                            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                                cache.flushAll();
                            }
                            return [2 /*return*/, { data: undefined, status: res.status }];
                        }
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                            cache.flushAll();
                        }
                        return [2 /*return*/, { data: json, status: res.status }];
                }
            });
        });
    };
    SDK.prototype._delete = function (path, params, query, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var hydratedPath, headers, res, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hydratedPath = findParamArguments(path, params).hydratedPath;
                        headers = { "Content-Type": "application/json" };
                        if (this._headers) {
                            headers = __assign(__assign({}, headers), this._headers);
                        }
                        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                            headers = __assign(__assign({}, headers), opts.headers);
                        }
                        return [4 /*yield*/, fetch("" + this._baseUrl + hydratedPath + generateQueryString(query), { method: "DELETE", headers: headers })];
                    case 1:
                        res = _a.sent();
                        if (res.status === 204) {
                            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                                cache.flushAll();
                            }
                            return [2 /*return*/, { data: undefined, status: res.status }];
                        }
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        if (!res.ok) {
                            return [2 /*return*/, { error: json, status: res.status }];
                        }
                        if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                            cache.flushAll();
                        }
                        return [2 /*return*/, { data: json, status: res.status }];
                }
            });
        });
    };
    return SDK;
}());
exports.default = SDK;
function findParamArguments(path, obj) {
    var parts = path.split(/\//);
    var params = parts.filter(function (p) { return p.length > 0 && p[0] === ":"; });
    var args = params.map(function (p) { return p.substring(1, p.length); });
    var out = {};
    var hydratedPath = path;
    if (obj) {
        args.forEach(function (e) {
            out[e] = obj[e];
            hydratedPath = hydratedPath.replace(":" + e, obj[e]);
        });
    }
    return { args: args, out: out, hydratedPath: hydratedPath };
}
function generateQueryString(opts) {
    return qs.stringify(opts, { addQueryPrefix: true, encode: true });
}
